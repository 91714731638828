* {
  font-family: helvetica;
  color: white;
 } 
  body {
    font-style: normal;
    font-family: Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    background-color: black;
  }

  #h1Tag {
    display: flex;
    justify-content: center;
  }

  .navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    background: deepskyblue;
    padding: 0; /* Remove padding to allow more flexibility */
  
    /* Add some space between navigation items */
    gap: 10px; /* Adjust as needed */
  }
  
  .navigation a {
    text-decoration: none;
    display: block;
    padding: 1em;
    color: white;
    text-align: center; /* Center text horizontally */
  
    /* Add flexibility for better scalability */
    flex: 1; /* Distribute available space evenly */
    min-width: 100px; /* Set a minimum width for navigation items */
    box-sizing: border-box; /* Include padding in item width */
  }
  
  .navigation a:hover {
    background: #1565C0;
  }
  
  @media all and (max-width: 800px) {
    .navigation {
      justify-content: space-around;
    }
  }
  
  @media all and (max-width: 600px) {
    .navigation {
      flex-direction: column;
    }
    .navigation a {
      padding: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .navigation li:last-of-type a {
      border-bottom: none;
    }
  }



.aboutMe {
  padding: 10px;
  line-height: 22px;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 50%;
  height: 100%;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 99%;
  color: whitesmoke;
  text-align: center;
  padding: 15px -2px;
}

/* footer {  
display: flex;
justify-content: center;
position: absolute;
width: 100%;
color: black;
text-align: center;
padding: 20px 0;
} */

.link-tag {
  text-decoration: none;
  color: black;
}

#img {
max-width: 100%;
height: auto;
height: 150px;
width: 150px;
margin-left: 40%;
margin-top: 30px;
margin-bottom: 30px;
justify-content: center;
flex-direction: column;
}


#img2 {
  max-width: 50%;
  height: auto;
  padding-top: 10px;
  margin: 30px auto; /* Center horizontally and provide top spacing */
  display: block; /* Change to block to center-align image */
}

.demo {
  max-width: 70%;
  max-height: 100%;
  height: auto;
  margin-left:30%;
  display: flex;
  justify-content: center;
  margin-inline-start: 10%;
  margin-bottom: 30px;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
}

hr {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: whitesmoke;
  text-align: center;
  height: 1.5em;
  opacity: 2;
  &:before {
    content: '';

    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: whitesmoke;

    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}

.project-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center horizontally */
  margin: 20px auto; /* Center vertically */
  padding: 10px;
  max-width: fit-content;
  width: 500px;
  height: 300px;
  background-color: black;
}


.demo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto; /* Center vertically */
  padding-left: auto;
  width:560px;
  height:315px;
}

#img-friends {
max-width: 100%;
max-height: 100%;
height: 350px;
width: 350px;
margin:auto;
padding-bottom: 5%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
}

#h1Tag-project {
  display: flex;
  justify-content: center;
}

.project-box-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center horizontally */
  margin: 20px auto; /* Center vertically */
  padding: 10px;
  max-width: fit-content;
  width: 500px;
  height: 100px;
  background-color: black;
}

.h2Tag {
  display: flex;
  justify-content: center;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}